import React from "react"
import { Link } from "gatsby"
import {
  Col,
  Container,
  Row,
} from 'reactstrap';

import Layout from "../components/Layout/layout"
import SEO from "../components/seo"
import BookingSuccessPage from "../components/BookingSuccessPage/BookingSuccessPage"
import "./about-us.scss"

import automobile from "../images/icons/services/automobile.png"
import development from "../images/icons/services/development.png"
import ecommerce from "../images/icons/services/ecommerce.png"
import crm from "../images/icons/services/crm.png"
import cms from "../images/icons/services/cms.png"
import ai from "../images/icons/services/ai.png"
import remoteoffice from "../images/icons/services/remoteoffice.png"

const AboutUsPage = () => (
  <Layout>
    <SEO title="About Us" />
    <Container className="aboutUs-container">
      <Row>
        <Col xs="10" sm="8" className="mx-auto d-flex flex-column justify-content-center">
          {/*<img src={checkImg} alt="checkImg" className="checkImg mx-auto"/>*/}
          <div className="pageTitle my-3">關於我們</div>
          <p className="content-text my-2">
            Cove (Driveclub Limited) 是一家於2017年建立的香港科技公司。剛開始的時候，本公司致力於推動本港汽車共享及租賃服務，打造Cove的品牌，並提供與出行相關的硬件和軟件技術解決方案，租車及網上買賣零件服務。
          </p>
          <p className="content-text my-2">
            我們構建內部科技方案的同時，本公司在汽車移動系統 / 軟硬件開發 / 電子商務軟件 (E-commerce) / 客戶關係管理(CRM) / 內容管理(CMS) / AI數據分析/ 遠程辦公室及數據管理方面累積大量開發經驗，並開拓出Driveclub的新業務範圍，專門負責為不同的初創公司和本地企業建立訂製科技方案，協助企業掌握尖端科技。
          </p>

          <div className="pageTitle my-3">訂製科技方案</div>

          <Row className="section-services my-4">
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={automobile} alt="" className="service-icon"/>
                <div className="service-title my-3">汽車移動系統</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={development} alt="" className="service-icon"/>
                <div className="service-title my-3">軟硬件開發</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={ecommerce} alt="" className="service-icon"/>
                <div className="service-title my-3">電子商務軟件</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={crm} alt="" className="service-icon"/>
                <div className="service-title my-3">客戶關係管理系統</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={cms} alt="" className="service-icon"/>
                <div className="service-title my-3">內容管理系統</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={ai} alt="" className="service-icon"/>
                <div className="service-title my-3">AI數據分析</div>
              </div>
            </Col>
            <Col sm="6" md="4">
              <div className="service-container">
                <img src={remoteoffice} alt="" className="service-icon"/>
                <div className="service-title my-3">遠程辦公室及數據管理</div>
              </div>
            </Col>
          </Row>

          <div className="p-4">
            <p className="contact-us-text my-2 text-center">
              如果你對我們的訂製服務有興趣的話歡迎
              <span>
                <a href="mailto:hello@covedrive.com">聯絡我們 </a>
              </span>
               😊
            </p>
          </div>
        </Col>
      </Row>

    </Container>
  </Layout>
)

export default AboutUsPage
